import $ from 'jquery';

window.$ = window.jQuery = $;

import 'glider-js/';

import './hamburger';
import './gallery';

const reviewsBox = document.querySelector('.reviews__box');

if (reviewsBox !== null) {
    new Glider(document.querySelector('.reviews__box'), {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        dots: '.dots',
        arrows: {
            prev: '.reviews__nav--prev',
            next: '.reviews__nav--next'
        },
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 970,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });
}
